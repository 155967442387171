import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import TeamsPage from "./TeamsPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { LOAD_MORE_COUNT, USE_API2_PROJECT_INFO } from "config";
import {
  GET_USER,
  GET_TEAMS,
  GET_TOPICS,
  GET_PROJECT_API2,
} from "services/api";
import { setProject, setButtons } from "actions";
import getApiGenerator from "services/getApiGenerator";
import getApiGenerator2 from "services/getApiGenerator2";
import listenerServices from "services/listenerServices";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    user: state.user,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
  };
};

export class TeamsContainer extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      teams: null,
      project: null,
      more: false,
      page: 1,
      searchInput: "",
      beganSearch: false,
      error: null,
      code: null,
      teamCreateEnabled: false,
      teamCreatedInProject: false,
      isLoadingMore: false,
      // login
      showLoginDialog: false,
    };
    // login
    this.handleOpenLoginDialog = this.handleOpenLoginDialog.bind(this);
    this.handleCloseLoginDialog = this.handleCloseLoginDialog.bind(this);

    this.handleMore = this.handleMore.bind(this);
    this.getTeams = this.getTeams.bind(this);
  }

  componentDidMount() {
    /* Retrieve Project information */
    if (this.props.projectId) {
      this.getTopics();
      this.getUserProfile();
      this.getProjectInfo(this.props.projectId);
      this.getTeams(this.state.page);
    }
    window.addEventListener("scroll", this.handleMore);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  /**
   * Get current user information:
   * To retrieve current team
   */
  getUserProfile() {
    getApiGenerator(
      GET_USER.format(this.props.user.id),
      {
        bundle_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            user: false,
          });
        }
      } else {
        this.setState({
          user: res.body,
        });
      }
    });
  }

  getProjectInfo(projectId) {
    if (!USE_API2_PROJECT_INFO) {
      this.getTopics();
      return;
    }

    getApiGenerator2(
      // API is only available in Platform 4.5.0
      GET_PROJECT_API2.format(projectId),
      {},
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body) {
          this.setState({
            code: res.body.code,
            error: "",
          });

          if (res.body.buttons) {
            // remap new params to old params
            let projectInfo = res.body;

            projectInfo["title"] = projectInfo["projectTitle"];
            projectInfo["description"] = projectInfo["projectDescription"];
            projectInfo["backgroundImage"] =
              projectInfo["projectBackgroundImage"];
            projectInfo["bannerImage"] = projectInfo["projectBannerImage"];

            this.setState({ project: projectInfo });

            this.props.setProject(projectInfo);
            this.props.setButtons(res.body.buttons);
          }
        } else {
          this.setState({
            code: 500,
            error: res.body.error,
          });

          // fallback method
          this.getTopics();
        }
      } else {
        this.setState({
          code: 500,
          error: res.body.error,
        });
      }
    });
  }

  getTopics() {
    // Fallback method to use API1 for getting project info
    // Topic list API is used to retrieve project info for sidebar & home buttons
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        this.setState({
          project: res.body.game,
          code: res.body.code,
          error: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  handleSearchChange(event) {
    if (!this.state.beganSearch) {
      this.setState({ beganSearch: true });
    }

    this.setState({ searchInput: event.target.value }, () => {
      this.getTeams(1, this.state.searchInput);
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleMore = debounce(() => {
    const {
      getTeams,
      state: { more, isLoadingMore, page, search },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getTeams(page, search);
      }
    }
  }, 100);

  getTeams(page, search = null) {
    getApiGenerator(
      GET_TEAMS.format(this.props.projectId),
      {
        page: page,
        keywords: search,
        limit: LOAD_MORE_COUNT,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            teams: [],
            more: false,
            page: 1,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamCreatedInProject: !!res.body.teamCreatedInProject,
            isLoadingMore: false,
          });
        }
      } else {
        if (page === 1) {
          this.setState({
            teams: res.body.data,
            more: res.body.more,
            page: page + 1,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamCreatedInProject: !!res.body.teamCreatedInProject,
            isLoadingMore: false,
          });
        } else {
          this.setState({
            teams: this.state.teams
              ? this.state.teams.slice().concat(res.body.data)
              : res.body.data,
            more: res.body.more,
            page: this.state.page + 1,
            teamCreateEnabled: res.body.game
              ? !!res.body.game.teamCreateEnabled
              : false,
            teamCreatedInProject: !!res.body.teamCreatedInProject,
            isLoadingMore: false,
          });
        }
      }
    });
  }

  handleOpenLoginDialog() {
    this.setState({
      showLoginDialog: true,
    });
  }

  handleCloseLoginDialog() {
    this.setState({
      showLoginDialog: false,
    });
  }

  render() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      !this.state.project &&
      this.state.code === 500
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (!!this.state.teams || this.state.beganSearch) {
      const user = this.state.user;

      if (user) {
        // Render with user's team information
        return (
          <TeamsPage
            isLoadingMore={this.state.isLoadingMore}
            teams={this.state.teams}
            teamCreateEnabled={this.state.teamCreateEnabled}
            teamCreatedInProject={this.state.teamCreatedInProject}
            user={this.props.user}
            team={user.team ? user.team.name : null}
            teamId={user.team ? user.team.id : null}
            teamImg={user.team ? user.team.image : null}
            more={this.state.more}
            handleMore={() => this.handleMore()}
            handleSubmit={this.handleSubmit.bind(this)}
            handleSearchChange={this.handleSearchChange.bind(this)}
            beganSearch={this.state.beganSearch}
            language={this.props.language}
            projectId={this.props.projectId}
            // login dialogs
            showLoginDialog={this.state.showLoginDialog}
            handleOpenLoginDialog={this.handleOpenLoginDialog}
            handleCloseLoginDialog={this.handleCloseLoginDialog}
            sessionKey={this.props.sessionKey}
          />
        );
      } else {
        // Render without user's team information
        return (
          <TeamsPage
            isLoadingMore={this.state.isLoadingMore}
            teams={this.state.teams}
            teamCreateEnabled={this.state.teamCreateEnabled}
            teamCreatedInProject={this.state.teamCreatedInProject}
            user={this.props.user}
            more={this.state.more}
            handleMore={() => this.handleMore()}
            handleSubmit={this.handleSubmit.bind(this)}
            handleSearchChange={this.handleSearchChange.bind(this)}
            beganSearch={this.state.beganSearch}
            language={this.props.language}
            projectId={this.props.projectId}
            // login dialogs
            showLoginDialog={this.state.showLoginDialog}
            handleOpenLoginDialog={this.handleOpenLoginDialog}
            handleCloseLoginDialog={this.handleCloseLoginDialog}
            sessionKey={this.props.sessionKey}
          />
        );
      }
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsContainer);
