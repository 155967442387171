import React, { Component } from "react";
import PropTypes from "prop-types";

import localize from "lang/localize";

const propTypes = {
  current: PropTypes.oneOf(["rewards", "redeemed"]).isRequired,
  handleToggle: PropTypes.func.isRequired,
  points: PropTypes.number,
  language: PropTypes.string,
};

class RewardsToggle extends Component {
  render() {
    const current = this.props.current;
    return (
      <div className="container toppadding-10 bottompadding-10">
        <nav className="nav-buttons">
          <button
            id="rewards"
            className={
              "button " + (current === "rewards" ? "active" : "inactive")
            }
            onClick={this.props.handleToggle}
          >
            <span>
              <i className="fas fa-gift"></i>
              {localize("nav_bar_title_empty_store_text", this.props.language)}
            </span>
          </button>
          <button
            id="redeemed"
            className={
              "button " + (current === "redeemed" ? "active" : "inactive")
            }
            onClick={this.props.handleToggle}
          >
            <span>
              <i className="fas fa-shopping-bag"></i>
              {localize("nav_bar_title_inventory_text", this.props.language)}
            </span>
          </button>
        </nav>
      </div>
    );
  }
}

RewardsToggle.propTypes = propTypes;

export default RewardsToggle;
