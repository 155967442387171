import { connect } from "react-redux";

import Topbar from "./Topbar";

import {
  mapStateToProps,
  mapDispatchToProps,
  TopbarContainer,
} from "components/Topbar/TopbarContainer";

import {
  /* For routing: getBackLink() */
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME,
  HOME,
  PROJECT_HOME,
  TOPIC,
  CHALLENGE,
} from "App/Routes";

import {
  USE_LOGIN_HOME_TEMPLATE_2,
  ENABLE_LOGIN_HOME,
  ENABLE_LOGIN_TOPBAR,
  ENABLE_TOUR_TOPBAR,
} from "config";

export class CustomTopbarContainer extends TopbarContainer {
  getBackLink() {
    switch (this.props.state) {
      case "reset-password":
      case "post-login":
        // Project Register or Project Reset Password
        if (this.props.projectId) {
          return PROJECT_LOGIN.format(this.props.projectId);
        }
        // Register or Reset Password
        else {
          return LOGIN;
        }

      case "login":
        if (this.props.projectId && ENABLE_LOGIN_HOME) {
          return PROJECT_LOGIN_HOME.format(this.props.projectId);
        } else if (ENABLE_LOGIN_HOME) {
          return LOGIN_HOME;
        } else {
          return "";
        }

      case "login-secondary":
        // Project Register or Project Reset Password
        if (
          this.props.projectId &&
          ENABLE_LOGIN_HOME &&
          USE_LOGIN_HOME_TEMPLATE_2
        ) {
          return PROJECT_LOGIN_HOME.format(this.props.projectId);
        } else if (this.props.projectId) {
          return PROJECT_LOGIN.format(this.props.projectId);
        }
        // Register or Reset Password
        else if (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_2) {
          return LOGIN_HOME;
        } else {
          return LOGIN;
        }

      case "search-projects":
      case "projects-list":
        return HOME;

      case "projecthome":
        if (this.isEmbeddedProject()) {
          return "";
        } else {
          return HOME;
        }

      case "topic":
        if (this.props.projectId) {
          let categoryHashId =
            window.location.hash.indexOf("#category") === 0
              ? window.location.hash.slice(9)
              : null;

          if (isNaN(categoryHashId) || !categoryHashId) {
            if (this.props.topic && this.props.topic.id) {
              return (
                PROJECT_HOME.format(this.props.projectId) +
                "#topic" +
                this.props.topic.id
              );
            } else {
              return PROJECT_HOME.format(this.props.projectId);
            }
          } else {
            //custom routing
            return (
              PROJECT_HOME.format(this.props.projectId) +
              "/category/" +
              categoryHashId
            );
          }
        } else {
          return "";
        }

      case "topic-category":
        if (this.props.projectId) {
          if (this.props.topic && this.props.topic.id) {
            return (
              PROJECT_HOME.format(this.props.projectId) +
              "#topic" +
              this.props.topic.id
            );
          } else {
            return PROJECT_HOME.format(this.props.projectId);
          }
        } else {
          return "";
        }

      case "challenge":
      case "challenge-category":
        if (this.props.topic && this.props.topic.id) {
          if (this.props.challenge && this.props.challenge.id) {
            return (
              TOPIC.format(this.props.topic.id) +
              "#challenge" +
              this.props.challenge.id
            );
          } else {
            return TOPIC.format(this.props.topic.id);
          }
        } else {
          return "";
        }

      case "challenge-comments":
        if (this.props.challenge && this.props.challenge.id) {
          return CHALLENGE.format(this.props.challenge.id);
        } else {
          return "";
        }

      default:
        return "";
    }
  }

  /* Custom: render Topbar from custom-components */
  render() {
    if (
      this.props.state === "no-topbar" ||
      (!ENABLE_LOGIN_TOPBAR &&
        (this.props.state === "login" || this.props.state === "login-home")) ||
      (!ENABLE_TOUR_TOPBAR && this.props.state === "login-tour")
    ) {
      return null;
    } else {
      return (
        <Topbar
          showMenu={this.state.showMenu}
          title={this.setTitle()}
          state={this.props.state}
          isMobileProjectPage={
            this.state.isMobilePage &&
            (this.props.state === "home-single" ||
              this.props.state === "projecthome")
          }
          isHome={this.isHome()}
          isEmbeddedProject={this.isEmbeddedProject()}
          loggedIn={this.isLoggedIn()}
          user={this.props.user}
          inboxUnread={this.props.inboxUnread || 0}
          projectId={this.props.projectId}
          handleBack={this.handleBack}
          backLink={this.getBackLink()}
          project={this.props.project}
          topicCategory={this.props.topicCategory}
          challengeCategory={this.props.challengeCategory}
          topic={this.props.topic}
          topicsList={this.state.topicsList}
          challenge={this.props.challenge}
          board={this.props.board}
          handleShowMenu={this.handleShowMenu}
          handleHideMenu={this.handleHideMenu}
          showSearchChallengeDialog={this.state.showSearchChallengeDialog}
          handleOpenSearchChallengeDialog={this.handleOpenSearchChallengeDialog}
          handleCloseSearchChallengeDialog={
            this.handleCloseSearchChallengeDialog
          }
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          showSelectTopicCategoryDialog={
            this.state.showSelectTopicCategoryDialog
          }
          showTopicsListDialog={this.state.showTopicsListDialog}
          handleOpenSelectTopicCategoryDialog={
            this.handleOpenSelectTopicCategoryDialog
          }
          handleCloseSelectTopicCategoryDialog={
            this.handleCloseSelectTopicCategoryDialog
          }
          handleOpenTopicsListDialog={this.handleOpenTopicsListDialog}
          handleCloseTopicsListDialog={this.handleCloseTopicsListDialog}
          showSelectChallengeCategoryDialog={
            this.state.showSelectChallengeCategoryDialog
          }
          handleOpenSelectChallengeCategoryDialog={
            this.handleOpenSelectChallengeCategoryDialog
          }
          handleCloseSelectChallengeCategoryDialog={
            this.handleCloseSelectChallengeCategoryDialog
          }
          showLanguageSelectionDialog={this.state.showLanguageSelectionDialog}
          handleOpenLanguageSelectionDialog={
            this.handleOpenLanguageSelectionDialog
          }
          handleCloseLanguageSelectionDialog={
            this.handleCloseLanguageSelectionDialog
          }
          showTourModal={this.state.showTourModal}
          handleOpenTourModal={this.handleOpenTourModal}
          handleCloseTourModal={this.handleCloseTourModal}
          handleRefresh={this.handleRefresh}
          language={this.props.language}
          sessionKey={this.props.sessionKey}
        />
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomTopbarContainer);
