import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
  LeaderboardContainer,
} from "components/Project/Leaderboard/LeaderboardContainer";
import LeaderboardPage from "components/Project/Leaderboard/LeaderboardPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import localize from "lang/localize";

export class CustomLeaderboardContainer extends LeaderboardContainer {
  constructor() {
    super();
    this.state = {
      requests: [],
      indivReqStatus: [],
      teamReqStatus: [],
      currentBoard: "individual",
      type: "monthly", //monthly is the default mode
      individual: {
        overall: null,
        monthly: null,
        daily: null,
      },
      team: {
        overall: null,
        monthly: null,
        daily: null,
      },
      topics: null,
      topicLeaderboards: null,
      selectedTopicLeaderboard: 0,
      error: null,
      code: null,
      overview: true,
      loadingData: false,
    };

    this.handleFetchData = this.handleFetchData.bind(this);
  }

  getPageData(type = "project", time = "monthly") {
    let requests = this.state.requests;

    if (this.props.project.teamEnabled && this.state.currentBoard === "team") {
      this.getPageTeamData(type, time);
    } else {
      if (type === "topic") {
        const req = this.getTopicLeaderboard(time);
        requests.push(req);
      } else {
        const req = this.getLeaderboard(time);
        requests.push(req);
      }

      this.setState({ requests: requests });
    }
  }

  getPageTeamData(type = "project", time = "monthly") {
    let requests = this.state.requests;

    if (type === "topic") {
      const req = this.getTopicTeamLeaderboard(time, this.props.team.id);
      requests.push(req);
    } else {
      const req = this.getTeamLeaderboard(time, this.props.team.id);
      requests.push(req);
    }

    this.setState({ requests: requests });
  }

  render() {
    if (!this.props.user.id) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (
      this.state.indivReqStatus.indexOf("monthly") >= 0 &&
      this.props.project.teamEnabled !== undefined
    ) {
      /* CUSTOM - monthly is default leaderboard */
      return (
        <LeaderboardPage
          projectId={this.props.projectId}
          mainBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].mainBoard
              : null
          }
          userBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].userBoard
              : null
          }
          moreBoardData={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type]
                  .moreBoardData
              : null
          }
          fullBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].data
              : null
          }
          more={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].more
              : null
          }
          topicLeaderboards={this.state.topicLeaderboards}
          type={this.state.type}
          board={this.state.currentBoard}
          teamAvailable={this.props.project.teamEnabled}
          handleToggleType={this.handleToggleType}
          handleToggleBoard={this.handleToggleBoard}
          handleToggleTopic={this.handleToggleTopic}
          handleToggleOverview={this.handleToggleOverview}
          handleFetchData={this.handleFetchData}
          overview={this.state.overview}
          userId={this.props.user.id}
          teamId={this.props.team.id}
          indivReqStatus={this.state.indivReqStatus}
          teamReqStatus={this.state.indivReqStatus}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomLeaderboardContainer);
