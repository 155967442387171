import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import QuizResult from "./QuizResult";
import SurveyResult from "./SurveyResult";
import ViewCompleted from "./ViewCompleted";
import ViewMyCompleted from "./ViewMyCompleted";

import {
  SHOW_CHALLENGE_MORE_COMPLETIONS,
  SHOW_CHALLENGE_MY_COMPLETIONS,
} from "config";

const propTypes = {
  id: PropTypes.number,
  sessionKey: PropTypes.string,
  userName: PropTypes.string,
  userPhoto: PropTypes.string,
  challengeTypeId: PropTypes.number.isRequired,
  correctAnswerNo: PropTypes.number,
  wrongAnswerNo: PropTypes.number,
  claimed: PropTypes.bool.isRequired,
  claimedBefore: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  correctAnswerTitle: PropTypes.string,
  correctAnswer: PropTypes.bool,
  quizOptions: PropTypes.array,
  gridWidth: PropTypes.number,
  privateClaim: PropTypes.bool,
  language: PropTypes.string,
};

class ChallengeResult extends Component {
  /**
   * Render results for poll-type challenges
   */
  renderResults() {
    switch (this.props.challengeTypeId) {
      case 4: //no completions are possible for flashcards
        return null;
      case 2: // quiz
      case 10: // repeatable quiz
        return (
          <Fragment>
            <div className="challenge-page-divider" />
            <QuizResult
              id={this.props.id}
              correctAnswerNo={this.props.correctAnswerNo}
              wrongAnswerNo={this.props.wrongAnswerNo}
              claimed={this.props.claimed}
              claimedBefore={this.props.claimedBefore}
              repeatUntilCorrect={this.props.repeatUntilCorrect}
              correctAnswerTitle={this.props.correctAnswerTitle}
              correctAnswer={this.props.correctAnswer}
              quizOptions={this.props.quizOptions}
              userName={this.props.userName}
              userPhoto={this.props.userPhoto}
              gridWidth={this.props.gridWidth}
              privateClaim={this.props.privateClaim}
              language={this.props.language}
            />
            {SHOW_CHALLENGE_MORE_COMPLETIONS && (
              <ViewCompleted
                id={this.props.id}
                language={this.props.language}
              />
            )}
          </Fragment>
        );
      case 3: // prediction - show results and 'Show More Completions' if user has completed, else only 'Show More Completions'
      case 11: // survey
        return (
          <Fragment>
            <div className="challenge-page-divider" />
            {this.props.claimed && (
              <SurveyResult
                quizOptions={this.props.quizOptions}
                userName={this.props.userName}
                userPhoto={this.props.userPhoto}
              />
            )}
            {SHOW_CHALLENGE_MORE_COMPLETIONS && (
              <ViewCompleted
                id={this.props.id}
                language={this.props.language}
              />
            )}
          </Fragment>
        );
      default:
        // standard/photo, video, multi-field, flashcard, validation code, key, invite, personality type + any others
        return (
          SHOW_CHALLENGE_MORE_COMPLETIONS && (
            <ViewCompleted id={this.props.id} language={this.props.language} />
          )
        );
    }
  }

  /**
   * Render link to display current user's completions
   */
  renderMyCompletions() {
    switch (this.props.challengeTypeId) {
      case 4: // flashcard
        return null;
      default:
        // flashcard, validation code, key, invite, personality type + any others
        return (
          <ViewMyCompleted
            id={this.props.id}
            sessionKey={this.props.sessionKey}
            language={this.props.language}
          />
        );
    }
  }

  /**
   * Renders the component
   */
  render() {
    return (
      <div>
        {this.renderResults()}
        {(SHOW_CHALLENGE_MORE_COMPLETIONS || SHOW_CHALLENGE_MY_COMPLETIONS) && (
          <div className="challenge-page-divider" />
        )}
        {SHOW_CHALLENGE_MY_COMPLETIONS && this.renderMyCompletions()}
      </div>
    );
  }
}

ChallengeResult.propTypes = propTypes;

export default ChallengeResult;
