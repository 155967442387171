import en from "./en.json";
import zhHans from "./zhHans.json";
import zhHant from "./zhHant.json";
import msMY from "./msMY.json";
import es from "./es.json";
import idReg from "./id.json";
import sv from "./sv.json";
import th from "./th.json";
import tr from "./tr.json";
import vi from "./vi.json";
import ptBR from "./ptBR.json";
import fr from "./fr.json";
import ko from "./ko.json";

/* Custom: custom lang packs */
import enCustom from "./en-custom.json";

function localize(id, language) {
  let lang;

  /* Use lower case */
  switch (language) {
    case "en":
    case "en-ar":
    case "en-au":
    case "en-bz":
    case "en-ca":
    case "en-cb":
    case "en-cn":
    case "en-dk":
    case "en-hk":
    case "en-in":
    case "en-id":
    case "en-ie":
    case "en-jm":
    case "en-ja":
    case "en-my":
    case "en-nz":
    case "en-no":
    case "en-ph":
    case "en-pr":
    case "en-sg":
    case "en-za":
    case "en-se":
    case "en-gb":
    case "en-us":
    case "en-zw":
    case "eng":
      //lang = Object.assign({}, en, enReact);
      lang = Object.assign({}, en, enCustom); //CUSTOM
      break;
    case "zh-hans":
    case "zh-sg":
    case "zh-cn":
      lang = Object.assign({}, zhHans, enCustom);
      break;
    case "zh-hant":
    case "zh-tw":
    case "zh-hk":
    case "zh-mo":
      lang = Object.assign({}, zhHant, enCustom);
      break;
    case "ms":
    case "ms-my":
      lang = Object.assign({}, msMY, enCustom);
      break;
    case "es":
    case "es-es":
    case "es-es_tradnl":
    case "es-mx":
    case "es-gt":
    case "es-cr":
    case "es-pa":
    case "es-do":
    case "es-ve":
    case "es-co":
    case "es-pe":
    case "es-ar":
    case "es-ec":
    case "es-cl":
    case "es-uy":
    case "es-py":
    case "es-bo":
    case "es-sv":
    case "es-hn":
    case "es-ni":
    case "es-pr":
    case "es-us":
    case "spa":
      lang = Object.assign({}, es, enCustom);
      break;
    case "fr":
      lang = Object.assign({}, fr, enCustom); // Fully machine translated
      break;
    case "pt":
    case "pt-br":
      lang = Object.assign({}, ptBR, enCustom); // Translation was provided by a Brazilian client
      break;
    case "id":
    case "id-id":
    case "ind":
      lang = Object.assign({}, idReg, enCustom);
      break;
    case "sv":
    case "swe":
      lang = Object.assign({}, sv, enCustom);
      break;
    case "th":
    case "tha":
      lang = Object.assign({}, th, enCustom);
      break;
    case "tr":
    case "tur":
      lang = Object.assign({}, tr, enCustom);
      break;
    case "vi":
    case "vie":
      lang = Object.assign({}, vi, enCustom);
      break;
    case "ko":
      lang = Object.assign({}, ko, enCustom);
      break;
    default:
      lang = Object.assign({}, en, enCustom);
  }

  return lang[id];
}

export default localize;
