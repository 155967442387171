import React from "react";
import { connect } from "react-redux";

import { HOME } from "App/Routes";
import {
  mapStateToProps,
  mapDispatchToProps,
  ProjectContainer
} from "components/Project/ProjectContainer";
import CustomProjectPage from "./CustomProjectPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { SINGLE_PROJECT_APP } from "config";
import localize from "lang/localize";

import sessionStorageService from "services/sessionStorageService";

/*
  In Project Container, this.props.id refers to category ID
  while this.props.projectId refers to projectId.
*/
export class CustomProjectContainer extends ProjectContainer {
  render() {
    const UNAVAILABLE_GAME_ERROR_LIST = [
      localize("unavailable_game_private", this.props.language),
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_no_topics", this.props.language),
      localize("unavailable_game_invalid", this.props.language)
    ];
    const UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN = [
      localize("unavailable_game_private_loggedin", this.props.language),
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_no_topics", this.props.language),
      localize("unavailable_game_invalid", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    /* Custom: first if: all project pages to be iframed */
    if (
      this.state.topics &&
      (this.state.topics.length !== 0 || this.state.categories)
    ) {
      return (
        <CustomProjectPage
          sessionKey={this.props.sessionKey}
          projectId={this.props.projectId}
          language={this.props.language}
          project={this.state.project}
          buttons={this.props.buttons}
        />
      );
    } else if (this.state.topics && !this.props.sessionKey) {
      /* No information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_game_long", this.props.language)}
          messageList={UNAVAILABLE_GAME_ERROR_LIST}
          endMessage={localize(
            "unavailable_game_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.topics) {
      /* No information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={SINGLE_PROJECT_APP || IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_game_long", this.props.language)}
          messageList={UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomProjectContainer);
