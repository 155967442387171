import React, { Component } from "react";
import PropTypes from "prop-types";

import { BASE_URL, SESSION_KEY_PARAMETER_APPENDED } from "config";
import deviceServices from "services/deviceServices";

const propTypes = {
  sessionKey: PropTypes.string,
  projectId: PropTypes.number.isRequired /* Project ID */
};

class CustomProjectPage extends Component {
  render() {
    const URL_SESSION_KEY =
      BASE_URL +
      "dashboard?id=" +
      this.props.projectId +
      SESSION_KEY_PARAMETER_APPENDED +
      this.props.sessionKey;

    return (
      <div
        className={
          "fullscreen-frame-wrap frame-with-user-widget" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <iframe
          src={URL_SESSION_KEY}
          title="project-page"
          frameBorder="0"
          className="fullscreen-frame"
        />
      </div>
    );
  }
}

CustomProjectPage.propTypes = propTypes;

export default CustomProjectPage;
