// App mode needs to be configured correctly as we do not have dynamic switching support in code.
export const SINGLE_PROJECT_APP = false;
// Platform needs to support session-less project view in other to support public project view without logging in.
export const REQUIRE_LOGIN = true;
/*
 Package/Project ID is taken from login API call.
 Fallback project is also used for login page default.
 Fallback package/project may also be used if incorrect API key is used.
*/
// Fallback project used for SINGLE_PROJECT_APP = true
export const PROJECT_ID = 1;
// Fallback package used for SINGLE_PROJECT_APP = false
export const MASTERS_ID = 1;

/**
 * LICENSED PROJECTS & API SETTINGS
 */

export const IS_LICENSED_PROJECT = true;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

/* Set Privacy Policy acceptance to be required */
export const SET_PRIVACY_POLICY_ACCEPTANCE_REQUIRED = !IS_LICENSED_PROJECT;

/**
 * URLS
 */

export const PLAYER_URL = process.env.REACT_APP_PLAYER_URL;
export const BASE_URL = process.env.REACT_APP_LICENSED_BASE_URL;
// Some pages may use Gametize.com as default URL, e.g. privacy page
// Use GAMETIZE_BASE_URL = BASE_URL otherwise
export const GAMETIZE_BASE_URL = "https://gametize.com/";

export const SECRET_KEY_PARAMETER = "?secret_key=";
export const SECRET_KEY_PARAMETER_APPENDED = "&secret_key=";
export const SESSION_KEY_PARAMETER = "?session_key=";
export const SESSION_KEY_PARAMETER_APPENDED = "&session_key=";
export const REACT_PARAMETER = "?react=true";
export const REACT_PARAMETER_APPENDED = "&react=true";
export const CALLBACK_URL_PARAMETER = "?callback_url="; // Doesn't seem to be in use!
export const CALLBACK_URL_PARAMETER_APPENDED = "&callback_url=";

export const SHOW_HELP_CENTER = false;
export const HELP_CENTER_URL =
  "https://support.gametize.com/hc/en-gb/categories/115001567688-For-Players";

/*
  Ensure that the entry exists in the database before configuring this.
  If this is empty, callback_url will be used instead and the root url of ReactJS will be used.
  However, callback_url may cause issues with AWS WAF.
*/
export const CALLBACK_ID_DEFAULT = "sellingschool";
export const CALLBACK_ID_PARAMETER = "?callback_id=";
export const CALLBACK_ID_PARAMETER_APPENDED = "&callback_id=";

export const API_PATH = "api/";
export const ENDPOINT = BASE_URL + API_PATH;

/**
 * For Gametize app layout
 */
export const API_PATH2 = "api2/";
export const ENDPOINT2 = BASE_URL + API_PATH2;

export const REGISTER_PAGE_LINK = BASE_URL + "registermobile";

/* Resend activation email after registering */
export const ENABLE_RESEND_ACTIVATION_LINK = !IS_LICENSED_PROJECT;
export const RESEND_ACTIVATION_LINK = BASE_URL + "resendactivationlink";

/* Use Gametize Privacy Page unless stated otherwise */
export const PRIVACY_PAGE_LINK = GAMETIZE_BASE_URL + "privacy";

export const SETTINGS_PAGE_LINK = BASE_URL + "settingsmobile";

/* Gametize platforms (beta and live) use passport/recoverpassword. Others use resetpassword. */
export const RECOVER_PASSWORD_PAGE_LINK = BASE_URL + "resetpassword";

export const ENQUIRY_PAGE_LINK = BASE_URL + "newenquiry";

export const INBOX_PAGE_LINK = BASE_URL + "enquiries";

export const TEAM_CREATE_PAGE_LINK = BASE_URL + "teamcreatemobile";
export const TEAM_INVITE_PAGE_LINK = BASE_URL + "teaminvitemobile";
export const TEAM_UPDATE_PAGE_LINK = BASE_URL + "teamupdatemobile";

/* Mini Game */
export const MINI_GAME_LINK = BASE_URL + "startminigame";

/*
  File streaming wrapper (fallback if encrypted url is not available)
*/
export const PDF_DOWNLOAD_LINK = BASE_URL + "getpdf?url={0}"; // Platform Version 4.8.0 required
// export const PDF_DOWNLOAD_LINK = BASE_URL + "getfile?url={0}"; // Platform Version 4.5.1 required

/**
 * ------------------------------------------------------------------------
 * SSO LOGINS
 * ------------------------------------------------------------------------
 */

// URLS: SSO Login
export const ENABLE_SSO_LOGIN = true;
export const ENABLE_SSO_TOKEN_LOGIN = true;
export const SSO_LOGIN_BASE_LINK = BASE_URL + "showssologin";
export const CALLBACK_ID_SSO = "sellingschool-sso";
/*
 Old URL is not desired due to these reasons:
 - Callback url is used (may trip WAF)
 - Session key is in URL parameter

 Instead, use a form post with SSO_LOGIN_BASE_LINK
*/
export const SSO_LOGIN_LINK =
  SSO_LOGIN_BASE_LINK +
  SECRET_KEY_PARAMETER +
  SECRET_KEY +
  REACT_PARAMETER_APPENDED +
  (ENABLE_SSO_TOKEN_LOGIN ? "&use_token=true" : "") +
  CALLBACK_ID_PARAMETER_APPENDED;

/* Facebook Login */
export const ENABLE_FACEBOOK_LOGIN = false;
/* Google Login */
export const ENABLE_GOOGLE_LOGIN = false;
/* Microsoft Login */
// NOT WORKING FOR SINGLE PROJECT APP!
export const ENABLE_MICROSOFT_LOGIN = false;

export const ENABLE_OAUTH_LOGIN =
  ENABLE_SSO_LOGIN ||
  ENABLE_FACEBOOK_LOGIN ||
  ENABLE_GOOGLE_LOGIN ||
  ENABLE_MICROSOFT_LOGIN;

/**
 * ------------------------------------------------------------------------
 * EXTERNAL API KEYS
 * ------------------------------------------------------------------------
 */

export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY;
export const GOOGLE_RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

/* Social Sign In */
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;
export const MICROSOFT_APP_ID = process.env.REACT_APP_MICROSOFT_APP_ID;

/* Google Login Analytics */
export const ENABLE_GOOGLE_ANALYTICS = true;
export const REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE;

/*
  Use Adobe PDF Embed API instead of Google PDF Viewer
  Still subjected to CORS rules!
  Remember to use the correct API key for each whitelabel app!
  Requires Platform v4.5.1 for wrapper to override CORS!
  Encrypted URL requires Platform v4.8.0
*/
export const USE_ADOBE_PDF_EMBED = false;
export const ADOBE_API_KEY = process.env.REACT_APP_ADOBE_API_KEY;

/**
 * ------------------------------------------------------------------------
 * LANGUAGE SETTINGS
 * ------------------------------------------------------------------------
 */

/*
  Set default language. Use a string like "en", in lowercase. If there is no default language,
  and you intend for the language to be selected based on the browser's navigator object or
  what's available for this project, just set to null.
*/
export const DEFAULT_LANGUAGE = null;

/*
  AVAILABLE_LANGUAGES refer all of the language packs we have for our React player
  web. You should not need to modify this config variable unless you are adding new language
  packs. It is presented as an array of objects, because we will using the strings in the
  objects for LanguageSelectInput.js, where we present objects as options in this manner:
  "[text] ([lang])", eg. "English (en)".
*/
export const AVAILABLE_LANGUAGES = [
  {
    lang: "en",
    text: "English",
  },
  {
    lang: "id",
    text: "Bahasa Indonesia",
  },
  {
    lang: "ms",
    text: "Bahasa Melayu",
  },
  {
    lang: "es",
    text: "Español",
  },
  {
    lang: "fr",
    text: "Français",
  },
  {
    lang: "pt",
    text: "Português",
  },
  {
    lang: "sv",
    text: "Svenska",
  },
  {
    lang: "tr",
    text: "Türkçe",
  },
  {
    lang: "vi",
    text: "Tiếng Việt",
  },
  {
    lang: "th",
    text: "ภาษาไทย",
  },
  {
    lang: "ko",
    text: "한국어",
  },
  {
    lang: "zh-Hans",
    text: "中文(简体)",
  },
  {
    lang: "zh-Hant",
    text: "中文(繁體)",
  },
];

/*
  AVAILABLE_LANGUAGES_STRINGS is an array of strings, unlike AVAILABLE_LANGUAGES.
  AVAILABLE_LANGUAGES_STRINGS just takes the values of the "lang" keys in
  AVAILABLE_LANGUAGES. Set to lowercase, because of URL params check.
*/
export const AVAILABLE_LANGUAGES_STRINGS = AVAILABLE_LANGUAGES.map((language) =>
  language["lang"].toLowerCase(),
);

/*
  AVAILABLE_LANGUAGES_VARIATIONS assumes all possible language codes for the languages
  available in AVAILABLE_LANGUAGES. These strings here should match the cases in
  localize.js and reduceLanguages.js.

  Will be checked against URL params, if any, so use lowercase.
*/
export const AVAILABLE_LANGUAGES_VARIATIONS = [
  /* English */
  "en",
  "en-ar",
  "en-au",
  "en-bz",
  "en-ca",
  "en-cb",
  "en-cn",
  "en-dk",
  "en-hk",
  "en-in",
  "en-id",
  "en-ie",
  "en-jm",
  "en-ja",
  "en-my",
  "en-nz",
  "en-no",
  "en-ph",
  "en-pr",
  "en-sg",
  "en-za",
  "en-se",
  "en-gb",
  "en-us",
  "en-zw",
  "eng",
  /* Simplified Chinese */
  "zh-hans",
  "zh-sg",
  "zh-cn",
  /* Traditional Chinese */
  "zh-hant",
  "zh-tw",
  "zh-hk",
  "zh-mo",
  /* Malay */
  "ms",
  "ms-my",
  /* Portuguese */
  "pt",
  "pt-br",
  /* Spanish */
  "es",
  "es-es",
  "es-es_tradnl",
  "es-mx",
  "es-gt",
  "es-cr",
  "es-pa",
  "es-do",
  "es-ve",
  "es-co",
  "es-pe",
  "es-ar",
  "es-ec",
  "es-cl",
  "es-uy",
  "es-py",
  "es-bo",
  "es-sv",
  "es-hn",
  "es-ni",
  "es-pr",
  "es-us",
  "spa",
  /* French */
  "fr",
  /* Bahasa Indonesia */
  "id",
  "id-id",
  "ind",
  /* Swedish */
  "sv",
  "swe",
  /* Thai */
  "th",
  "tha",
  /* Turkish */
  "tr",
  "tur",
  /* Vietnamese */
  "vi",
  "vie",
  /* Korean */
  "ko",
];

/*
  PLATFORM_AVAILABLE_LANGUAGES_STRINGS are the languages you have chosen out of AVAILABLE_LANGUAGES_STRINGS
  to be made available for this React platform. IT SHOULD ALWAYS BE AN ARRAY WITH AT LEAST ONE ITEM.

  If DEFAULT_LANGUAGE is not null, please include it in the array.

  Refer to the "lang" keys in AVAILABLE_LANGUAGES for valid strings to insert
  (in lowercase, because URL param check), eg. ["zh-hans", "ms"].

  By default, we will make all languages also available on the platform (thus assigned to
  AVAILABLE_LANGUAGES_STRINGS). Otherwise, please manually insert the languages you want.
*/
export const PLATFORM_AVAILABLE_LANGUAGES_STRINGS = AVAILABLE_LANGUAGES_STRINGS;

/*
  Similar to PLATFORM_AVAILABLE_LANGUAGES_STRINGS, except with variations.
  Use lowercase.
*/
export const PLATFORM_AVAILABLE_LANGUAGES_VARIATIONS =
  AVAILABLE_LANGUAGES_VARIATIONS;

/*
  VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS identifies all languages within PLATFORM_AVAILABLE_LANGUAGES_STRINGS
  which are also available in AVAILABLE_LANGUAGES_STRINGS. Consider this a second round of
  validity checks. The array returned should be language strings in lowercase.
*/
export const VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS =
  PLATFORM_AVAILABLE_LANGUAGES_STRINGS.filter(
    (platformLanguage) =>
      AVAILABLE_LANGUAGES_STRINGS.indexOf(platformLanguage) !== -1,
  );

/*
  Similar to VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS, except with variations.
  Use lowercase.
*/
export const VALID_PLATFORM_AVAILABLE_LANGUAGES_VARIATIONS =
  PLATFORM_AVAILABLE_LANGUAGES_VARIATIONS.filter(
    (platformLanguage) =>
      AVAILABLE_LANGUAGES_VARIATIONS.indexOf(platformLanguage) !== -1,
  );

/*
  By default, keep this disabled unless specified. This toggles the display of the
  language selector interface in the topbar but DOES NOT toggle the use of 'lang'
  URL params;.

  For app.gametize.com, we enable this. This returns all valid languages in
  VALID_PLATFORM_AVAILABLE_LANGUAGES_STRINGS.
*/
export const ENABLE_LANGUAGE_SELECTION = false;

/*
  Keep this enabled by default. Set this to false if you do not want your visitors
  to be able to change the language settings through modifying the URL parameters,
  eg. "?lang=es".
*/
export const ENABLE_LANG_URL_PARAM = true;

/*
  If platform has language pack support for embedded pages, enable this.
  Requires Platform v5.2.0+
*/
export const ENABLE_PLATFORM_LANG_URL_PARAM = true;
export const PLATFORM_LANGUAGE_PARAMETER = "lang=";

/**
 * ------------------------------------------------------------------------
 * INTERFACE SETTINGS
 * ------------------------------------------------------------------------
 */

/*
  If set to true, users will be able to see public projects
  when they route to /home if they are not signed in.
  Needs sessionless API support to be in place before enabling.
*/
export const SHOW_PUBLIC_PROJECT_LIST = false;

/*
  Use Gametize App Layout: Currently only built to work with SINGLE_PROJECT_APP = false;

  The following features will be enabled, if true:
  - Routing to custom (Gametize) Home page/component upon logging in.
  - Use of alias based URLs, /game/[projectCode]
  - Project Search
  - Featured Games
  - My Games
*/
export const USE_GAMETIZE_APP_LAYOUT = false;

/*
  Enables Project search on the Home screen (see app.gametize.com).
  This is available only if USE_GAMETIZE_APP_LAYOUT is true.
*/
export const ENABLE_HOME_SEARCH = false;

/*
  Enables Featured Games button on the Home screen (see app.gametize.com).
  This is available only if USE_GAMETIZE_APP_LAYOUT is true.
*/
export const ENABLE_FEATURED_GAMES = true;

/*
  Use deprecated API for featured projects.
  When disabled, page is routed to project list for configured package.
  Keep this enabled only if there are issues with transition.
*/
export const USE_DEPRECATED_FEATURED_GAMES_API = false;

/*
  Enables My Games button on the Home screen (see app.gametize.com).
  This is available only if USE_GAMETIZE_APP_LAYOUT is true.
*/

export const ENABLE_MY_GAMES = false;

/*
  Enables search interface in My Games page. This is available only
  if USE_GAMETIZE_APP_LAYOUT and ENABLE_MY_GAMES are true.
*/
export const ENABLE_MY_GAMES_SEARCH = false;

/*
  Login Home: When enabled, visitors will be routed to a page with Login and Register buttons,
  instead of the default Login page.

  Template 1: Similar to https://aspireacademy.singpost.com/ - Log in button, SSO Login button,
  both routing to different login pages. Guest log in button and register button are in
  email log in page.

  Template 2: Prapim's design - Log in button, guest log in button, SSO buttons, register button
  in Login Home page. Email login page should not have register button.

  For both templates below, make sure only one of Template 1 or Template 2 is true when enabling
  Login Home, otherwise the interface logic may break.

  USE_LOGIN_HOME_TEMPLATE_2 is used by Gourmet Grammar & Ninjaville.
  USE_LOGIN_HOME_TEMPLATE_1 is used by 1 client.

  TODO: Combine codes & use new configs to switch between different layouts
*/
export const USE_LOGIN_HOME_TEMPLATE_1 = false;
export const USE_LOGIN_HOME_TEMPLATE_2 = false;
export const ENABLE_LOGIN_HOME =
  USE_LOGIN_HOME_TEMPLATE_1 || USE_LOGIN_HOME_TEMPLATE_2;

/* Email Login */
export const ENABLE_EMAIL_LOGIN = true;

/* Registration */
export const ENABLE_REGISTER_PAGES = true;

/*
  Login automatically after registration.
  CALLBACK_ID_DEFAULT must be configured.
  Platform v5.3.0 Required.
*/
export const ENABLE_LOGIN_AFTER_REGISTER = false;

/* For rendering Settings pages, as well as link in topbar */
export const ENABLE_SETTINGS_PAGES = true;

/* For rendering Notifications (not Notifications tab in Inbox) pages, as well as link in profile drawer and mobile project home view */
export const ENABLE_NOTIFICATIONS_PAGES = true;

/* Project-specific Login, Register, Reset Password, and Settings Pages */
export const ENABLE_PROJECT_SPECIFIC_ACCOUNT_PAGES = !SINGLE_PROJECT_APP;

/* Project-specific Mobile App Download URL */
export const DOWNLOAD_APP_APPLE = null;
export const DOWNLOAD_APP_ANDROID = null;

/* For rendering Profile and Points in Masters/ProjectsListPage */
export const ENABLE_MASTERS_POINTS = false;

/* For rendering topbar in Login page, or Login Home page if ENABLE_LOGIN_HOME is true */
export const ENABLE_LOGIN_TOPBAR = false;

/* --- TOUR SUPPORT --- */
/* Important:
   If any of the ENABLE_TOUR* flags are true, ensure that either the API returns tour screens, or
   a TourSlider custom component must be implemented (for legacy tour screen support). */

/* For rendering Tour modals and link in topbar, and Tour page upon first login */
export const ENABLE_TOUR = false;

/* For rendering topbar in Tour page on first login (Tour page is a fake pop-up) */
export const ENABLE_TOUR_TOPBAR = false;

/* Allow tour to be accessible even when user is not logged in */
export const ENABLE_TOUR_PUBLIC = false;

/* Show/Hide "Skip tour" link */
export const ENABLE_TOUR_SKIP = true;

/* --- END TOUR SUPPORT --- */

/*
  Load more limit: set a standardised count for the number of items each
  page will load per page-based API call. For instance, the number of
  Topics the Project page will load initially, and for every subsequent time
  the "more" or "show more" button is clicked.
*/
export const LOAD_MORE_COUNT = 10;

/* Show Project background image from Dashboard's Customize Project settings */
export const SET_PROJECT_BG_IMG = true;

/*
  Enable project background image for sidebar in Desktop mode.
  SET_PROJECT_BG_IMG must be true as well.
*/
export const SET_PROJECT_BG_IMG_SIDEBAR = true;

/* Topic page settings */
export const SHOW_TOPIC_CHALLENGES_COMPLETED = true;
export const SHOW_TOPIC_ACHIEVEMENTS = true;

/*
  Show Topic Categories in the following places: Topic Category (eg. /category/[id]
  or /project/[projectId]/category/[categoryId]) pages, Topbar Menu's Select Category
  dialog in Project page
*/
export const SHOW_TOPIC_CATEGORIES = true;

/*
  Show Challenge Categories in the following places: Challenge Category (eg. topic/[id]/category/[id2],
  Topbar Menu's Select Category dialog in Topic page

  Enable it by default when feature is available in Flutter.
*/
export const SHOW_CHALLENGE_CATEGORIES = false;

/* Show Challenge Completions (in Challenge Page) */
export const SHOW_CHALLENGE_MORE_COMPLETIONS = true;
export const SHOW_CHALLENGE_MY_COMPLETIONS = true;

/*
  Challenge Settings:
  For comments, it can be found in "Comments Settings" instead
*/

/*
  Shows or hides only the Bookmark button in the Challenge card (in Topic
  view) or Challenge view. Hiding the Bookmark button in the floating
  buttons or project view buttons will still be left within the
  configuration in App Customiser (CMS).
*/
export const ENABLE_CHALLENGE_BOOKMARK_BUTTON = true;
/*
  Shows or hides vote/like statistics, and the vote button in Challenge
  card (in Topic view) or Challenge view.
*/
export const ENABLE_CHALLENGE_LIKES = true;
/*
  Shows or hides additional options button "..." for Challenge
*/
export const ENABLE_CHALLENGE_OPTIONS_BUTTON = true;
/*
  Shows or hides textbox for photo challenge
*/
export const ENABLE_PHOTO_CHALLENGE_TEXTBOX = true;

/* Project / Master Contact Email Settings (TO BE DEPRECATED) */
export const ENABLE_CONTENT_ENQUIRY = true;
export const USE_ENQUIRY_PAGE = true;
export const CONTACT_EMAIL_DEFAULT = "help@gametize.com";
export const CONTACT_EMAIL_TITLE_DEFAULT = null; // set to null if you do not have contact email title you want to use site-wide

/* Inbox */
export const ENABLE_INBOX = true;
// A Project-less Inbox's entry point is the list of Projects, while a Project Inbox shows you the messages from the Project's Inbox right away
export const ENABLE_PROJECTLESS_INBOX = true;
export const SHOW_PLATFORM_ANNOUNCEMENT = false; // Gametize Announcements
export const SHOW_NEWS_TAB = true;
export const SHOW_NOTIFICATIONS_TAB = false;
export const SHOW_MESSAGES_TAB = true;
export const ENABLE_TEAM_INBOX = true; // Requires Platform v5.2.0

/*
  Comments: Applied platform-wide
  Disabled since v5.1.1 in favour of Discussions
*/
export const ENABLE_TOPIC_COMMENTS = false;
export const ENABLE_CHALLENGE_COMMENTS = false;
export const ENABLE_CLAIM_COMMENTS = true; // not replaced by discussions

/*
  Discussions
  Comments module will be hidden (links not shown) when this is enabled
  Enabled by default in v5.1.1 (Requires Platform 4.0.0)
*/
export const ENABLE_DISCUSSIONS = true;

/* Leaderboard */
export const ENABLE_LEADERBOARD_TIME_FILTER = true;

/*
  Event Tracking
  Disabled by default as exit tracking is unrealiable
*/
export const ENABLE_EXIT_EVENT_TRACKING = false;

/* Following/Followers */
export const ENABLE_FOLLOWS = true;

/* Limit for number of mentions in a single comment */
export const MENTIONS_LIMIT = 5;

/* Abbreviate numbers to work around UI issues */
export const ABBREVIATE_NUMBER_THOUSANDS = true; // do not change this until tested well
export const ABBREVIATE_NUMBER_MILLIONS = true; // do not change this until tested well
export const ABBREVIATE_LEADERBOARD_POINTS = true; // configure for individual app if needed
// export const ABBREVIATE_USER_WIDGET_POINTS = true; // to be experimented with

/*
  If set to true, display only joined projects within the config's master ID
  Otherwise, display platform wide projects
  Set to false only for web-gametize
*/
export const SHOW_ONLY_MASTER_JOINED_PROJECTS = true;

/*
  Certivate
  Requires Platform 4.2.0
  Enabled for web-gametize for a start
*/
export const ENABLE_CERTIVATE = false;

/*
  Use new API call for project information.
  Requires Platform 4.5.0.
  This should be deprecated and removed in the future so that API2 will always be used.
*/
export const USE_API2_PROJECT_INFO = true;

/*
  Limit Gamification Features:
  By default, points, achievements and all KPIs are visible (enabled).
*/
export const ENABLE_POINTS = true;
export const ENABLE_ACHIEVEMENTS = true;
export const ENABLE_PROFILE_KPIS = true;

/*
  Hide completion feedback dialog for personality quiz.
  Default: Show completion feedback dialog.

  Feedback is still shown when:
  - Completion message is available.
  - Achievement unlocked.
  - Personality Quiz Topic completed.
*/
export const HIDE_PERSONALITY_QUIZ_COMPLETION_FEEDBACK = false;
/*
  Redirect to next challenge/topic if HIDE_PERSONALITY_QUIZ_COMPLETION_FEEDBACK = true.
  Close button will exhibit the same behaviour when HIDE_PERSONALITY_QUIZ_COMPLETION_FEEDBACK = true
  Default: Jump to next challenge instead of staying on current page.
*/
export const JUMP_TO_NEXT_FOR_PERSONALITY_QUIZ = true;
