import React, { Component } from "react";
import PropTypes from "prop-types";

import { BASE_URL, SESSION_KEY_PARAMETER } from "config";
import deviceServices from "services/deviceServices";

const propTypes = {
  sessionKey: PropTypes.string,
  language: PropTypes.string
};

class CustomProjectsListPage extends Component {
  render() {
    const URL_SESSION_KEY =
      BASE_URL + "home" + SESSION_KEY_PARAMETER + this.props.sessionKey;

    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <iframe
          src={URL_SESSION_KEY}
          title="project-list"
          frameBorder="0"
          className="fullscreen-frame"
        />
      </div>
    );
  }
}

CustomProjectsListPage.propTypes = propTypes;

export default CustomProjectsListPage;
