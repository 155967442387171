import Topbar from "components/Topbar/Topbar";

import { USE_LOGIN_HOME_TEMPLATE_2, ENABLE_LOGIN_HOME } from "config";
import localize from "lang/localize";

class CustomTopbar extends Topbar {
  renderBackText() {
    switch (this.props.state) {
      case "login":
        if (ENABLE_LOGIN_HOME) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "login-secondary":
        if (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_2) {
          return localize("icon_home", this.props.language);
        } else if (this.props.state === "login-secondary") {
          return localize("nav_bar_title_login_text", this.props.language);
        } else {
          return null;
        }
      case "reset-password":
      case "post-login":
        return localize("nav_bar_title_login_text", this.props.language);
      case "projects-list":
      case "search-projects":
        return localize("nav_bar_title_games_home_text", this.props.language);
      case "projecthome":
        if (this.props.isEmbeddedProject) {
          return null;
        } else {
          return localize("nav_bar_title_games_home_text", this.props.language);
        }
      case "topic":
        let categoryHashId =
          window.location.hash.indexOf("#category") === 0
            ? window.location.hash.slice(9)
            : null;

        if (isNaN(categoryHashId) || !categoryHashId) {
          return this.props.project && this.props.project.title
            ? this.props.project.title
            : localize("back_button_back_text", this.props.language);
        } else {
          return "Module";
        }
      case "topic-category":
        return this.props.project && this.props.project.title
          ? this.props.project.title
          : localize("back_button_back_text", this.props.language);
      case "challenge":
      case "challenge-category":
        return this.props.topic && this.props.topic.title
          ? this.props.topic.title
          : localize("back_button_back_text", this.props.language);
      case "challenge-comments":
        return this.props.challenge && this.props.challenge.title
          ? this.props.challenge.title
          : localize("back_button_back_text", this.props.language);
      case "home-single":
      case "home-multi":
      case "login-tour":
        return null;
      default:
        return localize("back_button_back_text", this.props.language);
    }
  }
}

export default CustomTopbar;
