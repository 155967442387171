import React, { Component } from "react";
import PropTypes from "prop-types";

import { SINGLE_PROJECT_APP } from "config";
import { ITEM, PROJECT_ITEM } from "App/Routes";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  earned: PropTypes.bool,
  description: PropTypes.string,
  unlockedAt: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
};

class Achievement extends Component {
  render() {
    let img = this.props.img;
    let title = this.props.title;
    let description = this.props.description;

    return (
      <Link
        to={
          this.props.projectId && !SINGLE_PROJECT_APP
            ? PROJECT_ITEM.format(this.props.projectId, this.props.id)
            : ITEM.format(this.props.id)
        }
      >
        <div className="pure-g bottommargin-10 card">
          <div className="pure-u-1-4">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={title} />
                {!this.props.earned && <div className="circle lockoverlay" />}
              </span>
            </span>
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-17-24 relative">
            <div className="topmargin-10">
              <strong>{title}</strong>
              {description && (
                <div className="responsive-img-description">
                  <p dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              )}
              {this.props.unlockedAt && (
                <p className="smalltext" id="unlockedAt">
                  {localize("unlocked_at_text", this.props.language).format(
                    this.props.unlockedAt,
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

Achievement.propTypes = propTypes;

export default Achievement;
