import React, { Component } from "react";
import { connect } from "react-redux";

import ProjectsListPage from "./ProjectsListPage";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language
  };
};

export class CustomProjectsListContainer extends Component {
  render() {
    return <ProjectsListPage sessionKey={this.props.sessionKey} />;
  }
}

export default connect(mapStateToProps)(CustomProjectsListContainer);
