import React, { Fragment } from "react";

import "./Login.css";
import TextInput from "components/shared/Inputs/TextInput";
import Link from "components/shared/Link/Link";
import LoginDivider from "components/Login/LoginDivider";
import LoginPage from "components/Login/LoginPage";

import login_logo from "images/custom/login/logo.png";
import login_logo_mobile from "images/custom/login/logo_mobile.png";

import {
  ENABLE_LOGIN_HOME,
  ENABLE_OAUTH_LOGIN,
  ENABLE_EMAIL_LOGIN,
} from "config";

import {
  PROJECT_REGISTER,
  REGISTER,
  PROJECT_RESET_PASSWORD,
  RESET_PASSWORD,
} from "App/Routes";

import localize from "lang/localize";

class PgSellingSchoolLoginPage extends LoginPage {
  /* Custom: Layout of SSO Login Section */
  renderSSOLoginSection() {
    if (ENABLE_OAUTH_LOGIN && !ENABLE_LOGIN_HOME) {
      return (
        <div className="pure-g box-sizing-border-box-all">
          <div className="fullwidth">
            <div className="pure-u-1-1 pure-u-sm-1-12" />
            <div className="pure-u-1-1 pure-u-sm-5-6 bottommargin-10 text-align-center">
              {this.renderUseSSOButtonContainer()}
              <a
                className="default privacy-policy"
                href={"https://pg.gametize.com/appterms"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Employee Privacy Policy
              </a>
            </div>
          </div>
          <div
            className={
              "pure-u-1-1" + (ENABLE_EMAIL_LOGIN ? " bottommargin-10" : "")
            }
          >
            <LoginDivider />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="login-register-container container verticalpadding">
        <div className="innerblock pure-g">
          <div className="hide-below-md pure-u-md-1-2 box-sizing-border-box-all horizontalpadding align-items-center">
            <img
              src={login_logo}
              alt="{this.props.projectTitle}"
              className="imgresponsive"
            />
          </div>
          <div className="pure-u-1-1 pure-u-md-1-2 box-sizing-border-box-all horizontalpadding align-items-center">
            <div className="fullwidth">
              <div className="logo-container hide-from-md">
                <img
                  src={login_logo_mobile}
                  alt="{this.props.projectTitle}"
                  className="logo-img"
                />
              </div>
              <div className="hide-below-md topmargin-40"></div>
              <div className="login-title">
                Welcome to
                <div className="project-title">AMA Selling School</div>
              </div>
              {/* Custom: Added renderSSOLoginSection() here */}
              {this.renderSSOLoginSection()}
              <form
                className="pure-form"
                onSubmit={this.handlePreSubmit.bind(this)}
              >
                <Fragment>
                  <TextInput
                    id="emailInput"
                    value={this.props.email}
                    onChange={this.props.handleEmailChange}
                    onKeyPress={this.handleKeyPress}
                    placeholder={localize(
                      "login_view_email_placeholder_text",
                      this.props.language,
                    )}
                  />
                  <TextInput
                    id="passwordInput"
                    type="password"
                    value={this.props.password}
                    onChange={this.props.handlePasswordChange}
                    onKeyPress={this.handleKeyPress}
                    placeholder={localize(
                      "login_view_password_placeholder_text",
                      this.props.language,
                    )}
                  />
                  <div className="pure-g bottommargin-10">
                    <div className="pure-u-1-1">
                      <p className="smalltext nomargin">
                        <Link
                          to={
                            this.props.projectId
                              ? PROJECT_RESET_PASSWORD.format(
                                  this.props.projectId,
                                )
                              : RESET_PASSWORD
                          }
                          className="default"
                        >
                          {localize(
                            "login_view_forgetpassword_text",
                            this.props.language,
                          )}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="pure-g bottommargin hide-below-md" />
                  <div className="pure-g bottommargin">
                    <div className="pure-u-1-1 pure-u-sm-1-12" />
                    <div className="pure-u-1-1 pure-u-sm-5-6">
                      <button
                        id="loginButton"
                        className={
                          "button fullwidth lowercase" +
                          (!!this.props.isSubmitLoading ? " disabled" : " cta")
                        }
                        type="submit"
                        disabled={!!this.props.isSubmitLoading}
                      >
                        {!!this.props.isSubmitLoading
                          ? localize(
                              "nav_bar_title_generic_loading",
                              this.props.language,
                            )
                          : localize("button_login", this.props.language)}
                      </button>
                    </div>
                  </div>
                  {/* Custom: Removed renderSSOLoginSection() here */}
                  <p className="textcenter text-registration">
                    <strong>
                      {localize(
                        "login_view_register_start",
                        this.props.language,
                      ) + " "}
                    </strong>
                    <Link
                      to={
                        this.props.projectId
                          ? PROJECT_REGISTER.format(this.props.projectId)
                          : REGISTER
                      }
                      className="default"
                    >
                      {localize("login_view_signup_text", this.props.language)}
                    </Link>
                  </p>
                </Fragment>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PgSellingSchoolLoginPage;
